import React, { Component, useEffect, useState } from 'react';
import MailchimpSubscribe from 'react-mailchimp-subscribe';
import './SignUp.css';
import './input.css';

const CustomForm = props => {
  const [email, setEmail] = useState('');
  const [fname, setName] = useState('');
  const [lname, setLname] = useState('');
  const [status, setStatus] = useState(null);
  const [message, setMessage] = useState(null);
  const [isSubscribed, setIsSubscribed] = useState(false);

  useEffect(() => {
    console.log('props.status', props.status);
    if (props.status === 'success') {
      setIsSubscribed(true);
      props.handleClose();
    }
  }, [props.status]);

  const handleInputChange = event => {
    const { name, value } = event.target;
    if (name === 'email') setEmail(value);
    if (name === 'fname') setName(value);
    if (name === 'lname') setLname(value);
  };

  const submit = () => {
    if (email && fname && lname && email.indexOf('@') > -1) {
      props.onValidated({
        EMAIL: email,
        FNAME: fname,
        LNAME: lname,
      });
    }
  };

  return (
    <>
      {/* <div className="signup-form"> */}
      <div className="flex-column p-6">
        <h3 className="text-lg font-bold text-black mb-3">Contact Form</h3>
        {props.status === 'sending' && <div className="sending">sending...</div>}

        {props.status === 'success' && (
          <div className="success" dangerouslySetInnerHTML={{ __html: message }} />
        )}
        <div class="form-group">
          <label for="password">Email</label>
          <input
            className="decoration-none"
            type="email"
            id="email"
            name="email"
            placeholder="Email"
            required
            onChange={handleInputChange}
          />
        </div>
        <div class="form-group">
          <label for="fname">First Name</label>
          <input
            type="text"
            id="fname"
            name="fname"
            placeholder="First Name"
            required
            onChange={handleInputChange}
          />
        </div>
        <div class="form-group">
          <label for="lname">Last Name</label>
          <input
            type="lname"
            id="lname"
            name="lname"
            placeholder="Last Name"
            required
            onChange={handleInputChange}
          />
        </div>
        {props.status === 'error' && (
          <div className="error" dangerouslySetInnerHTML={{ __html: props.message }} />
        )}
        <div className="button-groupd">
          <button
            className="close hover:text-blue-500"
            onClick={() => {
              console.log('test');
              props.handleClose();
            }}
          >
            Cancel
          </button>
          <button className="btn-submit " onClick={submit}>
            Confirm
          </button>
        </div>
      </div>
    </>
  );
};

const MailchimpSignupForm = props => {
  const url =
    'https://thegreenconsult.us21.list-manage.com/subscribe/post?u=f740831b16f2d14c9a98ba15b&amp;id=0bf7bac4c3&amp;f_id=00935ce1f0';
  return (
    <MailchimpSubscribe
      url={url}
      render={({ subscribe, status, message }) => (
        <CustomForm
          status={status}
          message={message}
          onValidated={formData => subscribe(formData)}
          handleClose={props.handleCloseMethod}
        />
      )}
    />
  );
};
export default MailchimpSignupForm;
